/* eslint-disable react/jsx-no-constructed-context-values */
import ReactDOM from "react-dom/client";
import React, { createContext } from "react";
import { BrowserRouter, HashRouter } from "react-router-dom";
import Store from "./store/store";
import App from "./App";
import "./fonts/gothampro.ttf";
import "./fonts/gothampro_light.ttf";
import "./fonts/gothampro_bold.ttf";
import "./index.css";

export const store = new Store();

const root = ReactDOM.createRoot(document.getElementById("root"));

export const Context = createContext({ store });

root.render(
  <React.StrictMode>
    <Context.Provider value={{ store }}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Context.Provider>
  </React.StrictMode>
);
