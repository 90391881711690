import React, { useState } from "react";
import { useContext } from "react";
import { Context } from "../../index";
import { Header } from "../Header";
import "./style.scss";
import { Artists } from "../pages/Artists";
import { Route, Routes, useLocation } from "react-router-dom";
import { useEffect } from "react";
import API from "../../utils/API";
import { ArtistCard } from "../ArtistCard/ArtistCard";
import { observer } from "mobx-react-lite";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { About } from "../pages/About";
import { Footer } from "../Footer";
import { Tickets } from "../pages/Tickets";

export const TG = observer(() => {
  const { store } = useContext(Context);
  const { appStore } = store;

  const location = useLocation();
  const [displayLocation, setDisplayLocation] = useState(location);
  const [transitionStage, setTransistionStage] = useState("fadeIn");

  useEffect(() => {
    if (location !== displayLocation) setTransistionStage("fadeOut");
  }, [location, displayLocation]);
  return (
    <div className="main">
      <Header />
      <div
        className={`${transitionStage}`}
        onAnimationEnd={() => {
          if (transitionStage === "fadeOut") {
            setTransistionStage("fadeIn");
            setDisplayLocation(location);
          }
        }}
      >
        <Routes location={displayLocation}>
          <Route path="/" element={<Artists />} />
          <Route path="/about" element={<About />} />
          <Route path="/:id" element={<ArtistCard />} />
          <Route path="*" element={<Artists />} />
        </Routes>
      </div>
      <Footer />
    </div>
  );
});
