import "./style.scss";
export const Footer = () => {
  return (
    <div className="footer">
      <p className="footer_line">Возникли вопросы?</p>
      <p className="footer_line">
        Обратитесь в <a href="#">ПОДДЕРЖКУ</a>
      </p>
    </div>
  );
};
