import { observer } from "mobx-react-lite";
import { Context } from ".";
import "./App.css";
import { Loader } from "./components/Loader";
import { TG } from "./components/TG";
import { useContext, useEffect, useState } from "react";
import API from "./utils/API";
import bg from "./components/TG/assets/bg.jpg";
import { useNavigate } from "react-router-dom";
function App() {
  const { store } = useContext(Context);
  const { appStore } = store;
  let tg = window.Telegram;
  const navigate = useNavigate();
  function preloadImage(src) {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = function () {
        resolve(img);
      };
      img.onerror = img.onabort = function () {
        reject(src);
      };
      img.src = src;
    });
  }

  useEffect(() => {
    API.get("/artists")
      .then((res) => {
        appStore.setArtists(res.data);
        if (res.data.length) {
          const imageList = [bg];
          for (let item of res.data) {
            imageList.push(item.mainImg);
            imageList.push(item.cardImg);
          }
          const imagesPromiseList = [];
          for (const i of imageList) {
            imagesPromiseList.push(preloadImage(i));
          }
          Promise.all(imagesPromiseList)
            .then(() => {
              setTimeout(() => {
                appStore.setLoaded(true);
                if (tg) {
                  tg.WebApp.expand();
                  if (tg.WebApp.initDataUnsafe.start_param) {
                    navigate("/" + tg.WebApp.initDataUnsafe.start_param);
                  }
                }
              }, 1000);
            })
            .catch((e) => {
              console.log(e);
            });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div className="App">{store.appStore.loaded ? <TG /> : <Loader />}</div>
  );
}

export default observer(App);
