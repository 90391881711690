import { Context } from "../../..";
import { useContext, useEffect } from "react";

export const About = () => {
  const { store } = useContext(Context);

  useEffect(() => {
    store.appStore.showBackArrow();
  }, []);

  return (
    <div
      style={{
        textAlign: "center",
        fontWeight: 300,
        minHeight: "70vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      Lorem ipsum dolor sit amet consectetur adipisicing elit. Est modi
      molestiae minima tempora? Quam, perferendis illum! <br /> <br />{" "}
      Exercitationem molestias quod perferendis, cumque obcaecati dolor adipisci
      tempora dolorum maxime doloribus, hic asperiores.
    </div>
  );
};
