import React from "react";
import { Artist } from "../../Artist";
import "./style.scss";
import { useState } from "react";
import { useEffect } from "react";
import API from "../../../utils/API";
import { useContext } from "react";
import { Context } from "../../..";
import { observer } from "mobx-react-lite";
import { Footer } from "../../Footer";

export const Tickets = observer(() => {
  const { store } = useContext(Context);
  const { appStore } = store;
  useEffect(() => {
    appStore.hideBackArrow();
    appStore.setArtistData({});
    appStore.setPage(2);
  }, []);
  return <div>dalee</div>;
});
