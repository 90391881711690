import React from "react";
import { Artist } from "../../Artist";
import "./style.scss";
import { useEffect } from "react";
import { useContext } from "react";
import { Context } from "../../..";
import { observer } from "mobx-react-lite";

export const Artists = observer(() => {
  const { store } = useContext(Context);
  const { appStore } = store;
  useEffect(() => {
    appStore.hideBackArrow();
    appStore.setArtistData({});
    appStore.setPage(0);
    if (process.env.NODE_ENV !== "development") {
      var WebApp = window.Telegram.WebApp;
      var BackButton = WebApp.BackButton;
      BackButton.hide();
    }
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        minHeight: "70vh",
        justifyContent: "space-between",
      }}
    >
      <div className="artists">
        {store.appStore.artists.map((item) => {
          if (item.enabled) {
            return <Artist key={item._id} data={item} />;
          }
        })}
      </div>
    </div>
  );
});
