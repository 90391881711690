import { observer } from "mobx-react-lite";
import React from "react";
import logo from "../../logo.png";
export const Loader = observer(() => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        background: "#000",
      }}
    >
      <img style={{ display: "block" }} alt="GNG" src={logo} />
    </div>
  );
});
