import React from "react";
import "./style.scss";
import { Link } from "react-router-dom";
import { useContext } from "react";
import { Context } from "../..";

export const Artist = ({ data }) => {
  const { store } = useContext(Context);

  return (
    <Link to={"/" + data.startParam}>
      <div
        className="artist_box"
        style={{ backgroundImage: `url(${data.mainImg})` }}
        onClick={() => {
          store.appStore.setPage(1);
        }}
      >
        <h3 className="artist_box__name"> {data.artistName}</h3>
      </div>
    </Link>
  );
};
