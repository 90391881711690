import React from "react";
import { useContext } from "react";
import { Context } from "../../index";
import logo from "../../logo.png";
import arrow from "./assets/arrow.svg";
import "./style.scss";
import { observer } from "mobx-react-lite";
import { Link, useNavigate } from "react-router-dom";

export const Header = observer(() => {
  const { store } = useContext(Context);
  const { appStore } = store;
  let navigate = useNavigate();

  function handleDevLink() {
    //TODO ПИЗДЕЦ
    if (appStore.page === 1) {
      return (
        <Link
          to={"/"}
          onClick={() => {
            setTimeout(() => {
              appStore.setPage(0);
            }, 300);
          }}
        >
          <div
            className={
              appStore.backArrow
                ? "header_left"
                : "header_left header_left--hidden"
            }
          >
            <img src={arrow} alt="back" />
            Назад
          </div>
        </Link>
      );
    }
    if (appStore.page === 2) {
      return (
        <Link
          to={"/" + appStore.startParam}
          onClick={() => {
            setTimeout(() => {
              appStore.setPage(1);
            }, 300);
          }}
        >
          <div
            className={
              appStore.backArrow
                ? "header_left"
                : "header_left header_left--hidden"
            }
          >
            <img src={arrow} alt="back" />
            Назад
          </div>
        </Link>
      );
    }
    if (appStore.page === 3) {
      return (
        <Link
          to={"/" + appStore.startParam}
          onClick={() => {
            setTimeout(() => {
              appStore.setPage(2);
            }, 300);
          }}
        >
          <div
            className={
              appStore.backArrow
                ? "header_left"
                : "header_left header_left--hidden"
            }
          >
            <img src={arrow} alt="back" />
            Назад
          </div>
        </Link>
      );
    }
    if (appStore.page === 4) {
      return (
        <Link
          to={"/artist/" + appStore.startParam}
          onClick={() => {
            setTimeout(() => {
              appStore.setPage(3);
            }, 300);
          }}
        >
          <div
            className={
              appStore.backArrow
                ? "header_left"
                : "header_left header_left--hidden"
            }
          >
            <img src={arrow} alt="back" />
            Назад
          </div>
        </Link>
      );
    }
  }
  function handleProdLink() {
    var WebApp = window.Telegram.WebApp;
    var BackButton = WebApp.BackButton;
    BackButton.show();

    BackButton.onClick(function () {
      // BackButton.hide();
      if (appStore.page === 1) {
        navigate("/");
        setTimeout(() => {
          appStore.setPage(0);
        }, 300);
      }
      if (appStore.page === 2) {
        navigate("/" + appStore.startParam);
        setTimeout(() => {
          appStore.setPage(1);
        }, 300);
      }
      if (appStore.page === 3) {
        navigate("/" + appStore.startParam);
        setTimeout(() => {
          appStore.setPage(2);
        }, 300);
      }
      if (appStore.page === 4) {
        navigate("/" + appStore.startParam);
        setTimeout(() => {
          appStore.setPage(3);
        }, 300);
      }
    });
  }

  return (
    <>
      <div className="header">
        <div className="header_left">
          {process.env.NODE_ENV === "development"
            ? handleDevLink()
            : handleProdLink()}
        </div>
        <a href="https://vk.com/gonagig" target="_blank" rel="noreferrer">
          <img src={logo} alt="GNG" />
        </a>
        <div className="header_right">
          <div
            onClick={() => appStore.toggleMenu()}
            className={
              appStore.openMenu ? "hamburger hamburger_open" : "hamburger"
            }
          >
            <div></div>
          </div>
        </div>
      </div>
      <menu className={appStore.openMenu ? "menu--open" : null}>
        <ul>
          {appStore.page !== 0 && (
            <li>
              <Link to="/" onClick={() => appStore.toggleMenu()}>
                ГЛАВНАЯ
              </Link>
            </li>
          )}

          {appStore.subscribes.length ? <li>МОИ АРТИСТЫ</li> : null}
          <li>
            {" "}
            <Link
              to="/about"
              onClick={() => {
                appStore.setPage(1);
                appStore.toggleMenu();
              }}
            >
              О GNG
            </Link>
          </li>
        </ul>
      </menu>
    </>
  );
});
